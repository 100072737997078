body {
  background-color: #f2f2f2;
}

.jss2,
.Component-paginationToolbar-1,
.Component-paginationToolbar-2,
.Component-paginationToolbar-3,
.Component-paginationToolbar-4,
.Component-paginationToolbar-5,
.Component-paginationToolbar-6 {
  width: auto !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.announcement-dangerously-html p {
  margin-block-start: 2px !important;
  margin-block-end: 2px !important;
}
